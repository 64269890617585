
export default {
  name: "SubMenuList",
  props: ['leftMenu', 'subMenuItem'],
  components: {
    Link : () => import("@/components/elements/Link.vue")
  },
  computed: {
    isObfuscated() {
      return this.$route.path != "/";
    },
  }
}
